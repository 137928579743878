// import { createApp } from 'vue'
import axios from 'axios'

// create an axios instance
const service = axios.create({
    // baseURL: 'http://192.168.1.109:8044/',
    baseURL: 'http://161.117.86.239:8044/',
    //withCredentials: true, // send cookies when cross-domain requests 注意：withCredentials和后端配置的cross跨域不可同时使用
    timeout: 6000, // request timeout
    crossDomain: true
})


export default service