<template>
  <div v-if="reload && review">
    <banner v-if="!submitForm && url!= '/login'" :word="word" @change="changeLanguage" @load="getBanner" />
    <router-view :mode="submitForm" @msg="getMsg" v-if="review && showBanner" />
    <foot v-if="!submitForm&& url!= '/login'" :word="word" :reload="review" />
    <div v-if="message" class="alert">
      <div :class="isSuccess ? 'success alert-content' : ' alert-content'">{{message}}</div>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import banner from './components/banner.vue'
import foot from './components/foot.vue'
import axios from '@/utils/request/request.js'
import word from './assets/json/language.json'
import base from '@/utils/request/base.js'


export default {
  name: 'App',
  components: {
    banner,
    foot
  },
  data() {
    return {
      submitForm: false,
      message: '',
      isSuccess: false,
      word: word,
      reload: false,
      showBanner: false,
      review: true,
      language: null,
      url: ''
    }
  },
  watch: {
    '$route': function (newVal, oldVal) {
      console.log(newVal, "555555");
      this.url = newVal.path
      if (newVal.path != oldVal.path) {
        this.reload = false;
        setTimeout(() => {
          this.reload = true;
        })
      }
    }
  },
  created() {
    this.word = word;
    this.reload = false;
    let hasLanguage = localStorage.getItem('language');
    if (!hasLanguage) {
      axios.get(`/ip`).then(res => {
        this.language = res.data == "CN" ? "CN" : "EN"
        this.getPath();
        localStorage.setItem('language', this.language);
        document.title = res.data == "CN" ? '禾优科技-全球短信/语音，更快速，更专业, 直连运营商，覆盖全球国家' : 'HeYouTech-Global SMS/Voice, faster and more professional, directly connected to operators, covering countries around the world'
        this.reload = true;
      })
      this.defaultHeight = 700 * window.innerWidth / 1920 - window.innerHeight / 2;
      setTimeout(() => {
        this.showBanner = true;
        this.reload = true;
        if (!this.language) {
          this.getPath();
          this.language = "EN"
          localStorage.setItem('language', this.language);
        }
      }, 800)
    } else {
      this.getPath();
      this.showBanner = true;
      this.reload = true;
    }
  },
  methods: {
    getPath() {
      if (this.$route.href == "/?submitForm") {
        this.submitForm = true;
      }
    },
    getMsg(e, boolean) {
      this.message = e;
      this.isSuccess = boolean;
      setTimeout(() => {
        this.message = ''
      }, 1500)
    },
    getBanner() {
      this.showBanner = true
    },
    changeLanguage() {
      this.review = false;
      setTimeout(() => {
        this.review = true;
      })
    }
  }
}
</script>

<style lang="scss">
.look-price {
  width: 300px;
  margin: 50px auto 100px;
  height: 60px;
  font-size: 20px;
  border-radius: 30px;
  cursor: pointer;
  line-height: 60px;
  text-align: center;
  background-color: #108ccf;
  color: #fff;
}
.alert {
  position: fixed;
  top: 12%;
  left: 0;
  width: 100%;
  z-index: 10070;
  .alert-content {
    border-radius: 16px;
    text-align: center;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    line-height: 50px;
    height: 50px;
    width: 500px;
    margin: 0 auto;
    font-size: 20px;
    color: #e6a23c;
    background-color: #fdf6ec;
    &.success {
      background-color: #f0f9eb;
      color: #67c23a;
    }
  }
}
body {
  width: 100%;
  padding: 0;
  margin: 0;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }
}
a {
  text-decoration: none;
  color: #393939;
}
@font-face {
  font-family: "iconfont"; /* Project id 2791796 */
  src: url("//at.alicdn.com/t/font_2791796_315ij951rya.woff2?t=1635217737958")
      format("woff2"),
    url("//at.alicdn.com/t/font_2791796_315ij951rya.woff?t=1635217737958")
      format("woff"),
    url("//at.alicdn.com/t/font_2791796_315ij951rya.ttf?t=1635217737958")
      format("truetype");
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
</style>
